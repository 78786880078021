/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import CircularProgress from '@mui/material/CircularProgress';
import Separator from "layouts/authentication/components/Separator";
import { Routes, Route, useParams } from 'react-router-dom';

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { verifyUserHash, setPassword } from "context/ApiService";
import IconButton from '@mui/material/IconButton';

import VisibilityOnIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import SoftAlert from "components/SoftAlert";
import { useForm } from 'react-hook-form';

import { styled } from "@mui/material"

function Forbidden() {
  const navigate = useNavigate();


    const StopImage = styled('img')({
        width: "100%"
    });

    const signOut = () => {
        window.localStorage.clear();
        navigate('/sign-in')
    }

    return (
        <BasicLayout
        title="Oops! Stop Here!"
        description={"Looks Like You dont have access to this application"}
        image={curved6}
        >
        <Card>
            <SoftBox pt={2} pb={3} px={3} sx={{textAlign: 'center'}}>
                <StopImage src="/assets/images/stop.jpg" />
                <SoftButton
                    variant="text"
                    color={"error"}
                    size="small"
                    circular
                    onClick={() => {signOut()}}
                >
                Sign out
                </SoftButton>
            </SoftBox>
        </Card>
        </BasicLayout>
    );
}

export default Forbidden;
