import React, { createContext, useContext, useState, useEffect } from "react";
import { getMyClients, getMyPermissions, loadConfig, me } from "../ApiService"
import { useNavigate } from "react-router-dom";
import sidenavItems from "context/sidenav";
import { setSidenavColor } from "context";

const UserContext = createContext();

export function useUser(){
    return useContext(UserContext);
}

export const UserProvider = ({ children }) => {

    const [ user, setUser ] = useState(null);
    const [ profile, setProfile ] = useState(null);
    const [ personal, setPersonal ] = useState(null);
    const [ config, setConfig ] = useState(null);
    const [ role, setRole ] = useState(null);
    const [ routes, setRoutes ] = useState([]);
    const [ clients, setClients ] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if(role){
          let { permissions } = role;
          let permittedModules = permissions.map((permission) => {
            if(permission.module_access.length) return permission.role_module.code;
            return false;
          }).filter(ele => ele);
    
          // Add dashboard so that dashboard is common for all
          permittedModules = [...permittedModules, "DASHBOARD"];
    
          let permittedRoutes = sidenavItems.map((route) => {
            let { id: _id } = route;
    
            let ids = _id.split(",");
    
            let idAllowed = ids.map(id => {
              if(permittedModules.indexOf(id) > -1) return id;
              return false;
            }).filter(ele => ele);
    
            if(idAllowed.length) return route;
            else return false;
    
          }).filter(ele => ele);
    
          setRoutes(permittedRoutes);
        } else {
            setRoutes([]);
        }
      }, [role]);

    useEffect(() => {
        loadMe();
        // setSidenavColor(dispatch, "error")
    }, [])

    useEffect(() => {
        if(role && config){
            let { reviewerRole } = config;
            let { _id } = role;
            console.log(role, config, "idididididid");
            if(reviewerRole && _id && reviewerRole != _id){
                navigate("/forbidden");
            }
        }
    }, [role, config]);

    const loadMe = async () => {
        try{
            let token = window.localStorage.getItem("token");
            if(token){
                let response = await me();
                let { success, data: userData, message } = response?.data;

                if(success){
                    let { personalInfo, user: userDetails, profile } = userData;
                    setUser(userDetails);
                    setProfile(profile);
                    setPersonal(personalInfo);
                    _loadConfig();
                    loadMyPermissions();
                    loadMyClients();
                }
            }
            
        }catch(error) {
            if(error?.response?.status == 401){
                navigate("/sign-in");
            }
        }
    }

    const loadMyPermissions = async () => {
        try{
            let token = window.localStorage.getItem("token");
            if(token){
                let response = await getMyPermissions();
                let { success, data: role, message } = response?.data;

                if(success){
                    setRole(role);
                }
            }
            
        }catch(error) {
            if(error?.response?.status == 401){
                navigate("/sign-in");
            }
        }
    }
    const loadMyClients = async () => {
        try{
            let token = window.localStorage.getItem("token");
            if(token){
                let response = await getMyClients();
                let { success, data, message } = response?.data;

                if(success){
                    setClients(data);
                }
            }
            
        }catch(error) {
            if(error?.response?.status == 401){
                navigate("/sign-in");
            }
        }
    }

    const _loadConfig = async () => {
        try{
            let token = window.localStorage.getItem("token");
            if(token){
                let response = await loadConfig();
                let { success, data, message } = response?.data;

                if(success){
                    setConfig(data);
                }
            }
            
        }catch(error) {
            if(error?.response?.status == 401){
                navigate("/sign-in");
            }
        }
    }

    return (
        <UserContext.Provider value={{ user, profile, personal, config, role, routes, clients, loadMe }}>
            { children }
        </UserContext.Provider>
    )

}