/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Clients from "layouts/application/clients";
import Tables from "layouts/tables";
import Users from "layouts/application/users";
import VerifyProfile from "layouts/authentication/verify-profile";
import Datasources from "layouts/application/datasources";
import SingleSearch from "layouts/application/search/general-search";
import BatchSearch from "layouts/application/batch-search";
import BatchSearchForm from "layouts/application/batch-search/SearchForm";
import Forbidden from "layouts/authentication/forbidden/forbidden";

const routes = [
  {
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    isProtected: true
  },
  {
    key: "profile",
    route: "/profile",
    component: <Profile />,
    isProtected: true
  },
  {
    key: "Accounts",
    route: "/accounts",
    component: <Clients />,
    isProtected: true
  },
  {
    key: "users",
    route: "/users",
    component: <Users isTenants={false} />,
    isProtected: true
  },
  {
    key: "client-users",
    route: "/client-users",
    component: <Users isTenants={true} />,
    isProtected: true
  },
  {
    key: "datasources",
    route: "/datasources",
    component: <Datasources isCredentials={false} />,
    isProtected: true
  },
  {
    key: "credentials",
    route: "/credentials",
    component: <Datasources isCredentials={true} />,
    isProtected: true
  },
  {
    key: "search",
    route: "/search",
    component: <SingleSearch />,
    isProtected: true
  },
  {
    key: "batch",
    route: "/batch",
    component: <BatchSearch />,
    isProtected: true,
  },
  {
    key: "batch-search",
    route: "/batch/upload",
    component: <BatchSearchForm />,
    isProtected: true,
    name: "Dashboard",
  },
  {
    key: "sign-in",
    route: "/sign-in",
    component: <SignIn />,
    isProtected: false
  },
  {
    key: "sign-up",
    route: "/sign-up",
    component: <SignUp />,
    isProtected: false
  },
  {
    key: "verify-profile",
    route: "/verify-profile/:hash",
    component: <VerifyProfile />,
    isProtected: false
  },
  {
    key: "forbidden",
    route: "/forbidden",
    component: <Forbidden />,
    isProtected: false
  },
];

export default routes;
