import React, { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { styled } from '@mui/system';
import * as XLSX from 'xlsx';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import UploadIcon from '@mui/icons-material/Upload';
import SoftButton from "components/SoftButton";

// Data
import projectsTableData from "layouts/tables/data/projectsTableData";

import { FileUploader } from "react-drag-drop-files";
import { useFieldArray, useForm } from "react-hook-form";
import { uploadBatchFile, startBatchProcess, loadWebsites } from "context/ApiService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "context/providers/UserProvider";
import { uploadDatasourceFile } from "context/ApiService";
import { addManualData } from "context/ApiService";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";

function BatchSearchForm() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { control, register, getValues, setValue, watch, errors, trigger } = useForm();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "filedMapping", // unique name for your Field Array
    });
    const navigate = useNavigate();
    const SwalAlert = withReactContent(Swal)

  const fieldMapping = [
    {mappingKey: "name", label: "Defaulter Name"},
    {mappingKey: "address", label: "Address"},
    {mappingKey: "dateOfBirth", label: "Date of Birth"},
    {mappingKey: "emailAddress", label: "Email Address"},
    {mappingKey: "mobileNumber", label: "Mobile Number"},
    {mappingKey: "organization", label: "Organization"},
    {mappingKey: "college", label: "College"},
    {mappingKey: "collegeStartDate", label: "College Start Date"},
    {mappingKey: "collegeEndDate", label: "College End Date"},
    {mappingKey: "degreeName", label: "Degree Name"},
    {mappingKey: "aadhaarNumber", label: "Aadhaar Number"},
    {mappingKey: "panNumber", label: "PAN"},
    {mappingKey: "drivingLicense", label: "Driving License"},
    {mappingKey: "rcNumber", label: "RC Number"},
    {mappingKey: "vehicleNumber", label: "Vehicle Number"},
    {mappingKey: "gstNumber", label: "GSTIN"},
    {mappingKey: "voterId", label: "Voter ID"},
    {mappingKey: "universalAccountNumber", label: "Universal Account Number"},
  ]

  const [datasources, setDatasources] = useState(false);
  const [datasource, setDatasource] = useState(false);
  const [batchUid, setBatchUid] = useState(null);

  const fileTypes = ["CSV", "XLS", "XLSX"];

  const [file, setFile] = useState(null);
  const [isFileOver, setFileOver] = useState(null);

  const { config, profile, user } = useUser();

  const handleChange = (file) => {
    setFile(file);
    readSheet(file);
  };
  const onDraggingStateChange = (dragging) => {
    setFileOver(dragging);
  };

  useEffect(() => {
    LoadData();
  }, []);

  useEffect(() => {
    setBatchUid(searchParams.get("uid"));
  }, [searchParams]);

    const LoadData = async () => {
        try{

            let response = await loadWebsites();
            let { success, data: datasources, message } = response.data;

            if(success){
                let allClients = datasources.map(datasource => {
                    let { uid, name, processType, id } = datasource;
                    return { label: `${name} (${processType ? processType : "AUTO"})`, uid, id };
                });
                console.log(allClients, "allClientsallClientsallClients");
                setDatasources(allClients);
            }
        }catch(error){
            // setLoading(false);
        }
    }

  const readSheet = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {raw: true });
        /* Update state */
        let firstRow = data.reduce((res, obj) => {
            res = {...res, ...obj};
            return res;
        });

        for (const [key, value] of Object.entries(firstRow)) {
            let form = {
                title: key,
                mappingKey: "",
                example: value,
                disable: key == "id"
            }
            append(form);
        }
    };
    reader.readAsBinaryString(file);
  }

  const onClickSubmit = () => {
    trigger();

    let filedMappingValues = getValues("filedMapping");

    let hasError = true;

    filedMappingValues.forEach(ele => {
        if(ele.mappingKey.trim()) hasError = false;
    });

    if(hasError){
        alert("Please select all values");
    }else {
        console.log(filedMappingValues, "filedMappingValuesfiledMappingValues");

        uploadFile();
    }
  }

  const uploadFile = async () => {
    try{
        let fd = new FormData();
        fd.append("file", file);
        fd.append("uid", batchUid);

        let response = await uploadDatasourceFile(fd);

        let respData = response.data;

        let { success, data, message } = respData;

        if(success){
            _startBatchProcess(data);
        }
    }catch(error){
        
    }
  }

  const _startBatchProcess = async (media) => {
    try{
        let { uid } = media;
        let filedMappingValues = getValues("filedMapping");

        let payload = {
            datasource,
            mediaUid: uid,
            batch: batchUid,
            fileTitleMapping: filedMappingValues
        }
        let response = await addManualData(payload);

        let { success, data } = response.data;
        if(success){
            SwalAlert.fire({
                icon: "success",
                title: "Successsfully Updated!",
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                navigate("/batch");
            })
        }
    }catch(error){
        
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
      <Card>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }} >

            <SoftBox p={3}>

                <FileUploader
                    class="ctrace-dropzone"
                    handleChange={handleChange}
                    onDraggingStateChange={onDraggingStateChange}
                    name="file"
                    label={file ? file.name : 'Drag and Drop file here or Click to select'}
                    types={fileTypes}
                    classes={"ctrace-drpzone"}
                    hoverTitle={"Drop it like its Hot!"}
                    dropMessageStyle={{
                        backgroundColor: '#17c1e8',
                        color: "#fff",
                        fontWeight: "bold"
                    }} />
                    
            </SoftBox>
                
          </SoftBox>
        </Card>
        {file && <SoftBox mt={3}>
            <Card>
                <SoftBox
                    sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        },
                    },
                    }} >

                    <SoftBox p={3}>
                    {/* <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftTypography component="h3" variant="h4" fontWeight="bold">
                            Settings
                        </SoftTypography>
                        <SoftButton type="button" color="primary" onClick={() => {onClickSubmit()}}>
                            Start Search&nbsp;<SearchTwoToneIcon />
                        </SoftButton>
                    </SoftBox> */}
                    <SoftBox mt={2} mb={4}>
                        <SoftTypography component="h6" variant="h6" color={'secondary'}>
                            Select Datasource
                        </SoftTypography>
                        <SoftBox>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                options={datasources}
                                sx={{ width: 300 }}
                                value={datasource}
                                onChange={(event, newValue) => {
                                    let { uid } = newValue;
                                    setDatasource(uid);
                                }}
                                getOptionLabel={option => {
                                    let c = datasources.find(ele => ele.uid == option);
                                    return c ? c.label : ""
                                }}
                                // renderOption={option => {
                                //     console.log(option, "optionoptionoption");
                                //     return option
                                // }}
                                renderOption={(props, option) => {
                                return (
                                    <li {...props}>
                                        {option.label}
                                    </li>
                                    )
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                />}
                            />
                        </SoftBox>
                    </SoftBox>
                    <SoftBox mt={2}>
                        <SoftTypography component="h6" variant="h6" color={'secondary'}>
                            Field Mapping
                        </SoftTypography>
                    </SoftBox>
                        <SoftBox mt={3}>
                            {fields.map((field, index) => (
                                <SoftBox mb={1} key={field.id}>
                                    {!getValues(`filedMapping.${index}.disable`) && <SoftBox display="flex">
                                        <SoftBox mb={1} mr={2} flex={1} display="flex" flexDirection="column">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                {getValues(`filedMapping.${index}.title`)}
                                            </SoftTypography>
                                            {getValues(`filedMapping.${index}.disable`) && <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Example:&nbsp;
                                                <SoftTypography component="label" variant="caption" fontWeight="normal">
                                                {getValues(`filedMapping.${index}.example`)}
                                                </SoftTypography>
                                            </SoftTypography>}
                                        </SoftBox>
                                        <SoftBox>
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                id="combo-box-demo"
                                                disabled={getValues(`filedMapping.${index}.disable`)}
                                                options={fieldMapping}
                                                sx={{ width: 300 }}
                                                {...register(`filedMapping.${index}.mappingKey`, {
                                                    required: "This Field Is Required"
                                                })}
                                                onChange={(event, newValue) => {
                                                    let { mappingKey } = newValue;
                                                    setValue(`filedMapping.${index}.mappingKey`, mappingKey);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </SoftBox>
                                        {/* {errors['filedMapping'][index]['mappingKey'] && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Key</SoftTypography>} */}
                                    </SoftBox>}
                                    <Divider />
                                </SoftBox>
                            ))}
                        </SoftBox>
                    </SoftBox>

                    <SoftBox display="flex" justifyContent="center" alignItems="center" m={3} mt="0">
                        <SoftButton type="button" color="primary" onClick={() => {onClickSubmit()}}>
                            Submit&nbsp;<UploadIcon />
                        </SoftButton>
                    </SoftBox>
                        
                </SoftBox>
            </Card>
        </SoftBox>}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BatchSearchForm;
