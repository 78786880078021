import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RefreshRounded } from "@mui/icons-material";
import { startReportGeneration, downloadReport, finishManualProcess } from "context/ApiService";
import Tooltip from '@mui/material/Tooltip';

import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";

const DownloadReport = (props) => {
    const [isOpne, setOpen] = useState(false);
    const [isOpneConfirm, setOpenConfirm] = useState(false);
    const { batch } = props;
    const { report } = batch;
    const SwalAlert = withReactContent(Swal)

    useEffect(() => {
        console.log(report?.processStatus, "reportreportreportreport");
    });

    const handleModalOpen = () => {
        setOpen(true);
    }

    const downloadReportByUid = async () => {
        
        try{
            let response = await downloadReport(report?.uid);

            var fileName = batch.name;
            var todayDate = new Date().toISOString().slice(0, 10);

            let FileName = `${fileName}-${todayDate}.xlsx`;

            const blob = response.data;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = FileName;
            link.click();

            link.remove();

        }catch(error){

        }
    }

    const startReportGenerationByReportUid = async () => {
        
        try{
            let response = await startReportGeneration(batch?.uid);

            handleModalClose();

        }catch(error){

        }
    }
    const _finishManualProcess = async () => {
        
        try{
            let response = await finishManualProcess(report?.uid);
            let { success, data, message } = response.data;
            if(success){
                SwalAlert.fire({
                    icon: "success",
                    title: "Successsfully Updated!",
                    showConfirmButton: false,
                    timer: 3000
                });
            }
            handleModalClose();
        }catch(error){

        }
    }

    const handleModalClose = (event, reason) => {
        if (reason && reason === "backdropClick")   return; 
        setOpen(false);
        setOpenConfirm(false);
    }
    
    const UpdateBrandModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle sx={{textAlign: 'center'}}>Download Report</DialogTitle>
                <DialogContent sx={{maxWidth: '300px'}}>
                    
                    {report?.processStatus == "GENERATING" || report?.processStatus == "REVIEWER_GENERATING" && <>
                        <SoftTypography sx={{textAlign: 'center'}} variant="body2" component="p" color="text">
                            Report is being generated for batch <SoftTypography variant="body2" component="span" color="text" fontWeight="bold">{batch?.name}</SoftTypography>
                            . Please wait for a while. You'll receive an email when generated.
                        </SoftTypography>
                    </>}
                    
                    {(report?.processStatus != "GENERATED" && report?.processStatus != "GENERATING" && report?.processStatus != "REVIEWER_GENERATED" && report?.processStatus != "REVIEWER_GENERATING") && <>
                        <SoftTypography sx={{textAlign: 'center'}} variant="body2" component="p" color="text">
                            Generate report for <SoftTypography variant="body2" component="span" color="text" fontWeight="bold">{batch?.name}</SoftTypography>. Generating Record may take some time and the status will be mailed as soon as the record is ready to download.
                        </SoftTypography>
                    </>}

                    {report?.processStatus == "GENERATED" || report?.processStatus == "REVIEWER_GENERATED" && <>
                        <SoftTypography sx={{textAlign: 'center'}} variant="body2" component="p" color="text">
                            Report for  <SoftTypography variant="body2" component="span" color="text" fontWeight="bold">{batch?.name}</SoftTypography> is ready to download. Click on download to start.
                        </SoftTypography>
                    </>}
                    <SoftBox mt={4} mb={1}>
                        {(report?.processStatus != "GENERATED" && report?.processStatus != "GENERATING" && report?.processStatus != "REVIEWER_GENERATED" && report?.processStatus != "REVIEWER_GENERATING" && report?.processStatus != "MANUAL_DONE") && <SoftButton type="submit" variant="gradient" color="error" fullWidth onClick={() => { startReportGenerationByReportUid() }}>
                            <FileDownloadIcon />&nbsp;Generate Report
                        </SoftButton>}
                        {(report?.processStatus == "GENERATING" || report?.processStatus == "REVIEWER_GENERATING")&& <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                            <RefreshRounded />&nbsp;Generating...
                        </SoftButton>}
                        {(report?.processStatus == "GENERATED" || report?.processStatus == "REVIEWER_GENERATED" || report?.processStatus == "MANUAL_DONE") && <SoftButton type="submit" variant="gradient" color="success" fullWidth onClick={() => {downloadReportByUid()}}>
                            <FileDownloadIcon />&nbsp;Download Report
                        </SoftButton>}
                        <SoftButton type="button" variant="text" color="error" fullWidth sx={{ marginTop: "15px"}} onClick={() => { setOpen(false) }}>
                            Close
                        </SoftButton>
                    </SoftBox>
                    
                </DialogContent>
            </Dialog>
        )
    }

    const ConfirmFinishModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle sx={{textAlign: 'center'}}>Download Report</DialogTitle>
                <DialogContent sx={{maxWidth: '300px'}}>
                    
                <SoftTypography sx={{textAlign: 'center'}} variant="body2" component="p" color="text">
                    By Confirming, The batch <SoftTypography variant="body2" component="span" color="text" fontWeight="bold">{batch?.name}</SoftTypography>&nbsp;
                    status will be changed to <SoftTypography variant="body2" component="span" color="text" fontWeight="bold">DONE</SoftTypography>, and You'll not be able to upload any more data manually.
                </SoftTypography>
                
                    <SoftBox mt={4} mb={1}>
                        <SoftButton type="submit" variant="gradient" color="error" fullWidth onClick={() => { _finishManualProcess() }}>
                            <CheckCircleIcon />&nbsp;Mark Done
                        </SoftButton>
                        <SoftButton type="button" variant="text" color="error" fullWidth sx={{ marginTop: "15px"}} onClick={() => { setOpenConfirm(false) }}>
                            Close
                        </SoftButton>
                    </SoftBox>
                    
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <SoftButton type="button" variant="text" color="error" onClick={handleModalOpen}>
                <FileDownloadIcon />&nbsp;Download
            </SoftButton>
            {
                (report?.processStatus == "REVIEWER_GENERATED" || report?.processStatus == "MANUAL_DONE") && 
                <Tooltip title="Upload Datasource File" placement="top">
                    <SoftButton type="link" color="dark" href={`/batch/upload?uid=${batch?.uid}`}>
                        <FileUploadIcon />
                    </SoftButton>
                </Tooltip>
            }
            {
                report?.processStatus == "MANUAL_DONE" && 
                <SoftBox ml={1}>
                    <Tooltip title="Finish Manual Process" placement="top">
                        <SoftButton type="button" color="info" onClick={() => { setOpenConfirm(true) }}>
                            <CheckCircleIcon />
                        </SoftButton>
                    </Tooltip>
                </SoftBox>
            }
            {isOpne && <UpdateBrandModal />}
            {isOpneConfirm && <ConfirmFinishModal />}
        </>
    )
}
export default DownloadReport;