import React, { useEffect, useState, useRef } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Card, Dialog, DialogTitle, DialogContent } from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from '@mui/system';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import EditIcon from '@mui/icons-material/Edit';
import SoftButton from "components/SoftButton";
import { updateWebsite, updateWebsiteLogo } from "context/ApiService";
import EditWebsiteForm from "./DatasourceActionForm"

function DatasourcePreview(props) {
    const { setReload, website, setWebsite, setDrawerOpen, isCredentials } = props;
    const [isEnabled, setEnabled] = useState(true);
    const inputFileRef = useRef( null );
    const [isOpen, setOpen] = useState(false);

    const handleModalOpen = () => {
        setOpen(true);
    }

    const handleModalClose = (event, reason) => {
        if (reason && reason === "backdropClick")   return; 
        setOpen(false);
    }

    const SizedSoftBox = styled(SoftBox)({
        height: "190px",
        flexDirection: 'column'
    });

    const StyledSoftBox = styled(SoftBox)({
        flexDirection: 'column'
    });
    const FlexSoftBox = styled(SoftBox)({
        flex: 1
    });
    const FlexSoftButton = styled(SoftButton)({
        flex: 1,
        marginLeft: isCredentials ? "0px" : "10px"
    });
    const RelativeSoftBox = styled(SoftBox)({
        position: "relative"
    });
    const HiddenFileInput = styled('input')({
        display: "none"
    });
    const FloatingSoftBox = styled(SoftBox)({
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        transition: "all 0.2s ease-out",
        cursor: "pointer",
        color: "#fff",
        background: "rgba(0,0,0,0.2)",
        borderRadius: "10px",
        "&:hover": {
            opacity: 1
        }
    });

    useEffect(() => {
        setEnabled(website?.isEnabled);
    }, [website])

    const WebsiteDomain = ({link}) => {
        let url = new URL(link);
        return <SoftTypography component="a" href={link} target="_blank" variant="caption" color="secondary">
            {url?.hostname || url?.host}
        </SoftTypography>
    }

    const updateWebsiteData = async (body) => {
        try{
            let response = await updateWebsite(website?.uid, body);
            let { success, data, message } = response.data;

            if(success){
                setReload(true);
            }            
        }catch(error){

        }
    }

    const _updateWebsiteLogo = async (body) => {
        try{
            let response = await updateWebsiteLogo(website?.uid, body);
            let { success, data, message } = response.data;

            if(success){
                setReload(true);
            }            
        }catch(error){

        }
    }

    const updateEnabledState = (state) => {
        let body = { isEnabled: state };
        updateWebsiteData(body);
    }

    const CreatedBy = ({user}) => {
        return <SoftTypography component="div" variant="caption" color="secondary">
            {user?.firstName} {user?.lastName}
        </SoftTypography>
    }

    const CreatedOn = ({createdAt}) => {
        let date = new Date(createdAt).toLocaleString("en-IN", { day: '2-digit', month: 'short', year: 'numeric'});
        return <SoftTypography component="div" variant="caption" color="secondary">
            {date.toString()}
        </SoftTypography>
    }

    const onLogoChnage = ( e ) => {
        let files = e.target.files;
        
        if(files.length){
            let logo = files[0];
            console.log(logo, "logologologo");
            let fd = new FormData();
            fd.append("file", logo);
            _updateWebsiteLogo(fd);
            e.target.value = null;
        }
    }
    const onBtnClick = () => {
        inputFileRef.current.click();
    }

    const onClickOfCredentials = () => {
        setWebsite(website);
        setDrawerOpen(true);
    }

    const AddWebsiteModal = () => {
        return (
            <Dialog onClose={handleModalClose} open={true}>
                <DialogTitle>Update Website</DialogTitle>
                <DialogContent>
                    <EditWebsiteForm {...props} setOpen={setOpen} website={website} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <Card>
                <SizedSoftBox p={2} display="flex" justifyContent="start" alignItems="start">
                    <SoftBox display="flex" justifyContent="start" alignItems="start" sx={{width: "100%"}}>
                        <RelativeSoftBox>
                            <SoftAvatar
                                src={website?.logo? website?.logo?.url: "/assets/images/404.jpg"}
                                alt="profile-image"
                                variant="rounded"
                                size="md"
                                shadow="sm"
                            />
                            {!isCredentials && <FloatingSoftBox onClick={onBtnClick}>
                                <EditIcon />
                            </FloatingSoftBox>}
                            <HiddenFileInput
                                type="file"
                                id={`file-input-${website?.uid}`}
                                ref={inputFileRef}
                                accept="image/png, image/jpg, image/jpeg, image/webp"
                                onChange={(e) => {
                                    onLogoChnage(e);
                                }}
                            />
                        </RelativeSoftBox>
                        <FlexSoftBox>
                            <StyledSoftBox px={2} display="flex" justifyContent="start" alignItems="start">
                                <SoftTypography component="h6" variant="h6" fontWeight="bold">
                                    {website?.name}
                                </SoftTypography>
                                <WebsiteDomain link={website?.url} />
                            </StyledSoftBox>
                        </FlexSoftBox>
                    </SoftBox>
                    <SoftBox mt={2} display="flex" justifyContent="start" alignItems="start" sx={{width: "100%"}}>
                        <FlexSoftBox display="flex" justifyContent="start" alignItems="start">
                            <StyledSoftBox display="flex" justifyContent="start" alignItems="start">
                                <SoftTypography component="h6" variant="h6" fontWeight="bold">
                                    Created By
                                </SoftTypography>
                                <CreatedBy user={website?.createdBy} />
                            </StyledSoftBox>
                        </FlexSoftBox>
                        <FlexSoftBox display="flex" justifyContent="end" alignItems="start">
                            <StyledSoftBox display="flex" justifyContent="start" alignItems="end">
                                <SoftTypography component="h6" variant="h6" fontWeight="bold">
                                    Created On
                                </SoftTypography>
                                <CreatedOn createdAt={website?.createdAt} />
                            </StyledSoftBox>
                        </FlexSoftBox>
                    </SoftBox>
                    <SoftBox mt={2} display="flex" justifyContent="start" alignItems="center" sx={{width: "100%"}}>
                        {!isCredentials && <Switch checked={isEnabled} onChange={(event) => {
                            let status = !isEnabled;
                            setEnabled(status);
                            updateEnabledState(status);
                        }} />}
                        <FlexSoftButton component="button" variant="gradient" color="dark" size="small" onClick={onClickOfCredentials}>Credentials</FlexSoftButton>
                        {!isCredentials && <FlexSoftButton component="button" variant="outlined" size="small" color={"error"} onClick={handleModalOpen}>Update</FlexSoftButton>}
                    </SoftBox>
                </SizedSoftBox>
            </Card>
            {isOpen && <AddWebsiteModal />}
        </>
    )
}

export default DatasourcePreview;