import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import ListOfDatasources from "./ListOfDatasources";

import { useLocation } from 'react-router-dom';

function Datasources(props) {
    return (
        <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
            <ListOfDatasources {...props} />
        </SoftBox>
        <Footer />
        </DashboardLayout>
    );
}

export default Datasources;
