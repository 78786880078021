import React, { useEffect, useState } from "react";

import SoftButton from "components/SoftButton";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAlert from "components/SoftAlert";
import { inviteUser, roles as getRoles } from "context/ApiService";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { loadAllClients } from "context/ApiService";

const AddNewUserForm = (props) => {
    const { reloadState, setOpen , config, isTenants } = props;
    const [reload, setReload] = reloadState;
    const { register, handleSubmit, formState: { errors }, trigger, setValue } = useForm();
    const [roles, setRoles] = useState([]);

    const [showClients, setShowClients] = useState(false);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        loadRoles();
        loadClients();
    }, []);

    useEffect(() => {
        let { defaultRole, superAdminRole } = config;
        let role = roles.find(r => (r.id == (isTenants ? defaultRole : superAdminRole)));
        setShowClients(isTenants);
        if(role){
            let { uid } = role;
            setValue("role", uid);
        }
    }, [isTenants, roles]);

    const loadRoles = async () => {
        try{
            let rolesResponse = await getRoles();
            let { success, data } = rolesResponse.data;
            let userRoles = data.map(role => {
                let { uid, name: label, id } = role;
                return { label, uid, id }
            });
            setRoles(userRoles);
        }catch(error){
            console.log(error, "errorerrorerror");
        }
    }

    const loadClients = async () => {
        try{
            let rolesResponse = await loadAllClients();
            let { success, data } = rolesResponse.data;
            let clientsList = data.map(client => {
                let { uid, name: label, id } = client;
                return { label, uid, id }
            });
            setClients(clientsList);
        }catch(error){
            console.log(error, "errorerrorerror");
        }
    }

    const triggerSubmit = (formData, event) => {
        saveClient(formData);
    }

    const saveClient = async (formData) => {
        try{
            let response = await inviteUser(formData);
            let { success, data, message } = response.data;
            if(success){
                setOpen(false);
                setReload(true);
            }
        }catch(error){

        }
    }
    
    return (
        <SoftBox component="form" onSubmit={handleSubmit((data, event) => triggerSubmit(data, event))} role="form" sx={{ width: "300px" }}>
            <SoftBox mb={0.5}>
                <SoftBox ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    First Name
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('firstName', {
                        required: true,
                        minLength: 5
                    })}
                    placeholder="First Name"
                    error={errors.firstName}
                />
                {errors.firstName && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid First Name</SoftTypography>}
            </SoftBox>
            <SoftBox mb={0.5}>
                <SoftBox ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Last Name
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="text"
                    {...register('lastName', {
                        required: true
                    })}
                    placeholder="Last Name"
                    error={errors.lastName}
                />
                {errors.lastName && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid Last Name</SoftTypography>}
            </SoftBox>
            <SoftBox mb={0.5}>
                <SoftBox ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Email Address
                    </SoftTypography>
                </SoftBox>
                <SoftInput
                    type="email"
                    {...register('emailAddress', {
                        required: 'Email is required',
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid email',
                        },
                    })}
                    placeholder="Email Address"
                    error={errors.emailAddress}
                />
                {errors.emailAddress && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid email address</SoftTypography>}
            </SoftBox>
            {/* <SoftBox mb={0.5}>
                <SoftBox ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Role
                    </SoftTypography>
                </SoftBox>
                <Autocomplete
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    options={roles}
                    sx={{ width: 300 }}
                    {...register('role', {
                        required: 'Email is required'
                    })}
                    onChange={(event, newValue) => {
                        let { uid, id } = newValue;
                        let { defaultRole } = config;
                        if(id == defaultRole) setShowClients(true);
                        else {
                            setShowClients(false);
                            setValue("clients", []);
                        }

                        setValue("role", uid);
                    }}
                    renderInput={(params) => <TextField {...params}/>}
                />
                {errors.role && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid role</SoftTypography>}
            </SoftBox> */}
            <SoftBox mb={0.5} sx={{display: showClients? 'block' : 'none'}}>
                <SoftBox ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Client
                    </SoftTypography>
                </SoftBox>
                <Autocomplete
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    options={clients}
                    sx={{ width: 300 }}
                    {...register('clients', {
                        required: 'Client is required'
                    })}
                    onChange={(event, newValue) => {
                        let { uid } = newValue;
                        setValue("clients", [uid]);
                    }}
                    renderInput={(params) => <TextField {...params}/>}
                />
                {errors.role && <SoftTypography color="error" component="label" variant="caption" fontWeight="bold">Please enter Valid role</SoftTypography>}
            </SoftBox>
            <SoftBox mt={4} mb={1}>
            <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                Save
            </SoftButton>
            <SoftButton type="button" variant="text" color="error" fullWidth sx={{ marginTop: "15px"}} onClick={() => { setOpen(false) }}>
                Cancel
            </SoftButton>
            </SoftBox>
        </SoftBox>
    )
}
export default AddNewUserForm;